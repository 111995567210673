import request from '@/utils/request';
import { PagedCollection } from "@/types/hydra";
import { ContractApprovingList, ContractModification, ContractStageApprover } from '@/types/Contract';

const baseUri = '/contract_';

// Тип поправить
export const getContractAmendments = (params: object): Promise<PagedCollection<ContractModification>> =>
    request({
        url: `${baseUri}amendments`,
        method: 'GET',
        params
    });

export const getContractAmendment = (id: string): Promise<ContractModification> =>
    request({
        url: `${baseUri}amendments/${id}`,
        method: 'GET'
    });

export const getApprovers = (id: string): Promise<PagedCollection<ContractStageApprover>> =>
    request({
        url: `${baseUri}amendments/${id}/approvers`,
        method: 'GET',
    });

export const startApproving = (id: string, data: object) =>
    request({
        url: `${baseUri}amendments/${id}/start_approving`,
        method: 'POST',
        data,
    });

export const approve = (id: string, data: object) =>
    request({
        url: `${baseUri}amendments/${id}/approve`,
        method: 'POST',
        data,
    });

export const decline = (id: string, data: object) =>
    request({
        url: `${baseUri}amendments/${id}/decline`,
        method: 'POST',
        data,
    });

export const reject = (id: string, data: object) =>
    request({
        url: `${baseUri}amendments/${id}/reject`,
        method: 'POST',
        data,
    });

export const getContractModifications = (params: object): Promise<PagedCollection<ContractModification>> =>
    request({
        url: `${baseUri}modifications`,
        method: 'GET',
        params
    });

export const getContractModification = (id: string): Promise<ContractModification> =>
    request({
        url: `${baseUri}modifications/${id}`,
        method: 'GET'
    });

export const createContractModification = (data: object): Promise<ContractModification> =>
    request({
        url: `${baseUri}modifications`,
        method: 'POST',
        data
    });

export const deleteContractModification = (id: string): Promise<void> =>
    request({
        url: `${baseUri}modifications/${id}`,
        method: 'DELETE'
    });

export const updateContractModification = (id: string, data: object): Promise<ContractModification> =>
    request({
        url: `${baseUri}modifications/${id}`,
        method: 'PATCH',
        data
    });

export const getContractTerminations = (params: object): Promise<PagedCollection<ContractModification>> =>
    request({
        url: `${baseUri}terminations`,
        method: 'GET',
        params
    });

export const getContractTermination = (id: string): Promise<ContractModification> =>
    request({
        url: `${baseUri}terminations/${id}`,
        method: 'GET'
    });

export const createContractTermination = (data: object): Promise<ContractModification> =>
    request({
        url: `${baseUri}terminations`,
        method: 'POST',
        data
    });

export const deleteContractTermination = (id: string): Promise<void> =>
    request({
        url: `${baseUri}terminations/${id}`,
        method: 'DELETE'
    });

export const updateContractTermination = (id: string, data: object): Promise<ContractModification> =>
    request({
        url: `${baseUri}terminations/${id}`,
        method: 'PATCH',
        data
    });

export const getContractAmendmentsRejectTransitions = (id: string): Promise<void> =>
    request({
        url: `amendments/${id}/reject_transitions`,
        method: 'GET'
    });

export const getContractAmendmentsApprovingList = (id: string): Promise<ContractApprovingList> =>
    request({
        url: `amendments/${id}/approving_list`,
        method: 'GET'
    });

export const deadlineContractAmendments = (id: string, data: object) =>
    request({
        url: `amendments/${id}/deadline`,
        method: 'POST',
        data,
    });

export interface GetTimelineRequest {
    id: string;
    lt?: string;
    onlyComments?: boolean;
}

export const getContractAmendmentTimeline = ({ id, lt, onlyComments }: GetTimelineRequest): Promise<any> =>
    request({
        url: `amendments/${id}/timeline`,
        method: 'GET',
        params: {
            lt,
            onlyComments,
        },
    });
